import React, { useState } from "react";
import "./App.css";
import blob from "./blob.gif";

function App() {
  const [formData, setFormData] = useState({
    gridName: "",
    units: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Form submitted! Grid Name: ${formData.gridName}, Units: ${formData.units}`);
  };

  return (
    <div className="App flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500">
      <header className="App-header flex flex-col items-center gap-8 p-8 bg-white/90 backdrop-blur-md shadow-lg rounded-xl max-w-md w-full">
        <img
          style={{ maxWidth: "100%" }}
          src={blob}
          className="App-blob rounded-full"
          alt="blob"
        />
        <h1 className="text-3xl font-bold text-gray-900">Create Your New Grid</h1>
        <p className="text-gray-600 text-center">
          Start building your personalized energy grid in just a few steps.
        </p>

        {/* Form */}
        <form onSubmit={handleSubmit} className="flex flex-col gap-6 w-full">
          {/* Grid Name */}
          <div className="flex flex-col">
            <label
              htmlFor="gridName"
              className="text-sm font-medium text-gray-700 mb-2"
            >
              Grid Name
            </label>
            <input
              type="text"
              id="gridName"
              name="gridName"
              placeholder="My Awesome Grid"
              value={formData.gridName}
              onChange={handleChange}
              className="w-full px-4 py-3 text-gray-900 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          {/* Number of Units */}
          <div className="flex flex-col">
            <label
              htmlFor="units"
              className="text-sm font-medium text-gray-700 mb-2"
            >
              Number of Units
            </label>
            <input
              type="number"
              id="units"
              name="units"
              placeholder="e.g., 50"
              value={formData.units}
              onChange={handleChange}
              className="w-full px-4 py-3 text-gray-900 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full px-4 py-3 text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-500 rounded-lg shadow transition duration-300"
          >
            Create Grid
          </button>
        </form>
      </header>
    </div>
  );
}

export default App;
